div.MuiDialogActions-root {
	display: block !important;
	flex: 0 0 auto !important;
}

div button.reportBtn,
div button.excelBtn {
	margin: 12px;
}

div.reportModalWrapper {
	padding: 5px;
}
