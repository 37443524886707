div.leaseSwitch {
	margin-right: 100px;
	font-weight: 600;
}

div.leaseSwitch:hover,
.switchBtn:hover {
	cursor: pointer;
}

div.leaseSwitch div.active {
	color: #037aab;
}

div.saveDivider {
	height: 20px;
	border-left: 1px solid lightgrey;
	margin: 0 18px 0 14px;
}
