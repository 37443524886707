div.deleteConfirmOptions {
	display: flex;
	align-items: center;
	justify-content: space-around;
	padding: 10px;
}

div.deleteRecordBtn button {
	margin-right: 100px;
	color: #9b3c3c;
}

div.saveDivider {
	height: 20px;
	border-left: 1px solid lightgrey;
	margin: 0 18px 0 14px;
}
