.FilterInput {
	display: flex;
	align-items: center;
}

.FilterInput .dateDivider {
	display: flex;
	align-items: center;
	margin: 0 10px;
}
