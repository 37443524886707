.ViewData {
	display: flex;
	align-content: center;
	flex-direction: column;
}

.ViewData .gm-style div {
	border: none !important;
}

.ViewData .ViewData-filterRow {
	width: 100%;
	padding: 20px 50px 10px 70px;
	display: flex;
	border-bottom: 1px solid #c1c1c1;
	align-items: center;
	justify-content: flex-start;
	position: sticky;
	top: -1px;
	z-index: 999;
	background: #fff;
}

.ViewData .doneViewingNewComp {
	margin-left: 10px;
}

.ViewData-filterDropdown label {
	background-color: #fff;
	padding-right: 4px;
}

.ViewData-ReportComps svg {
	color: #e7e7e7;
}

.ViewData .ViewData-noRecordsMsg {
	display: flex;
	justify-content: center;
	display: flex;
	margin-top: 218px;
	justify-content: center;
}

.ViewData .clearCompsBtn {
	margin-right: 20px;
	color: #7594b4;
}

.ViewData .ViewData-noRecordsMsg h4 {
	font-weight: 100;
	font-style: italic;
}

.ViewData .ViewData-search {
	margin-left: 10px;
}

.ViewData-ReportComps.ViewData-ReportCompsAdded svg {
	color: dodgerblue;
}

.ViewData .ViewData-searchAndFilter {
	display: flex;
	min-width: 400px;
	width: auto;
	justify-content: space-between;
}

option.ViewData-option {
	padding: 10px 14px;
}

option.ViewData-option:hover {
	cursor: pointer;
	background-color: #e7e7e7;
}

option.ViewData-optionSelected {
	background-color: #f7f7f7;
}

.ViewData .ViewData-contents {
	width: 100vw;
	margin-top: 45px;
	margin-bottom: 30px;
	display: flex;
	flex-direction: row;
	padding-top: 10px;
}

.ViewData .ViewData-comps {
	padding: 0 20px;
	margin: 0 auto;
	display: flex;
	align-items: center;
	justify-content: space-around;
	flex-wrap: wrap;
	max-width: 1400px;
}

.ViewData .ViewData-map {
	width: 50%;
}

.ViewData .ViewData-spinner {
	display: flex;
	width: 100%;
	align-content: center;
	justify-content: center;
}

.ViewData .ViewData-spinner.ViewData-spinner--hidden {
	display: none !important;
}

.ViewData .ViewData-spinner div {
	margin-top: 90px;
}

.ViewData div.viewDataLeaseSwitch {
	font-weight: 600;
	margin-right: 30px;
	margin-left: 20px;
	display: flex;
}

.ViewData div.compViewSwitch {
	font-weight: 600;
	padding: 0 20px;
	margin: 0;
	margin-left: 10px;
	display: flex;
}

.ViewData div.switchSeparator {
	border-right: 1px solid darkgray;
}

.ViewData div.reportsBtnSeparator {
	margin-right: 20px;
}

.ViewData div.compViewSwitch:hover,
.ViewData div.viewDataLeaseSwitch:hover,
.ViewData .switchBtn:hover {
	cursor: pointer;
}

.ViewData div.compViewSwitch div.active,
.ViewData div.viewDataLeaseSwitch div.active {
	color: #037aab;
}

.ViewData div.viewDataRightSide {
	display: flex;
	justify-content: flex-end;
	flex-direction: row;
	align-items: center;
	flex-grow: 1;
	padding-right: 15px;
}
