.Home.Home-bg {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: 0 70px;
	position: absolute;
	top: 0;
	height: 100vh;
	width: 100vw;
}

.Home {
	display: flex;
	align-content: center;
	flex-direction: column;
}

option.Home-option {
	padding: 10px 14px;
}

option.Home-option:hover {
	cursor: pointer;
	background-color: #e7e7e7;
}

option.Home-optionSelected {
	background-color: #f7f7f7;
}

.Home .Home-locationInput {
	border: 1px solid #008ebb;
	border-radius: 4px;
	font-size: 18px;
	padding: 10px;
	height: 24px;
}

.Home .underConstruction {
	font-size: 20px;
	margin-top: 30px;
	color: #008ebb;
	background: #fff;
	border-radius: 9px;
	border: 1px solid #008ebb;
	padding: 20px;
}

.Home .underConstruction span {
	font-weight: 500;
}

.Home .btn-success,
.Home .btn-success:hover,
.Home .Home-cta .dropdown button,
.Home .Home-cta .dropdown button:active,
.Home .Home-cta .show.dropdown button:focus,
.Home .btn-success:not(:disabled):not(.disabled):active:focus {
	border: 1px solid #008ebb;
	background-color: #fff;
	color: #373c3e;
	box-shadow: none;
	padding: 0 20px;
	height: 46px;
	width: 175px;
	font-size: 17px !important;
}

.Home .btn-success.dropdown-toggle::after {
	display: none;
}

.Home .Home-searchBtn {
	background-color: #008ebb;
	border-radius: 4px;
	padding: 10px 23px;
	font-size: 18px;
	color: #fff;
	border: none;
	font-weight: 500;
	height: 46px;
}

.Home .SingleDatePicker .DateInput_input {
	width: 130px;
	border: 1px solid #008ebb;
	border-radius: 4px;
}

.Home .Home-ctaContainer .SingleDatePickerInput,
.Home .Home-ctaContainer .DateInput_input__focused,
.Home .Home-ctaContainer .DateInput_input {
	border: none;
}

.Home .Home-searchBtn:hover {
	cursor: pointer;
}

.Home-content .Home-ctaContainer button:active,
.Home-content .Home-ctaContainer button:focus,
.Home-content .Home-ctaContainer button:hover {
	box-shadow: none;
	border-color: #5f7584;
}

.Home-content .Home-ctaContainer fieldset.MuiOutlinedInput-notchedOutline,
.Home-content .Home-ctaContainer fieldset.MuiOutlinedInput-notchedOutline,
.Home-content .Home-ctaContainer fieldset.MuiOutlinedInput-notchedOutline {
	box-shadow: none;
	border-color: #4a4a4a;
}

.Home-content .Home-ctaContainer label.Mui-focused,
.Home-content .Home-ctaContainer label.Mui-focused,
.Home-content .Home-ctaContainer label.Mui-focused {
	color: #4a4a4a;
}

.Home .Home-locationInput:focus,
.Home .Home-searchBtn:focus {
	outline: none !important;
}

.Home .Home-ctaContainer {
	width: 610px;
	font-size: 23px;
	color: #373c3e;
	padding: 27px;
	background: #fff;
	margin-top: 24px;
	border-radius: 5px;
	display: flex;
	justify-content: space-around;
	margin: auto;
	margin-top: 50px;
}

.Home .btn-outline-secondary.Home-catBtn.Home-catSelected {
	color: #fff;
	background-color: #5f7584;
	border-color: #5f7584;
}

.Home .btn-outline-secondary.Home-catBtn.Home-catSelected:focus,
.Home .btn-outline-secondary.Home-catBtn.Home-catSelected:active,
.Home .btn-outline-secondary.Home-catBtn.Home-catSelected:hover {
	background-color: #5f7584;
	color: #fff;
	box-shadow: none;
}

.Home .btn-outline-secondary.Home-catBtn:hover,
.Home button.btn.btn-outline-secondary:hover {
	background-color: #e7e7e7;
	color: #333;
}

.Home .SingleDatePicker .CalendarDay.CalendarDay__selected,
.Home .SingleDatePicker .CalendarDay.CalendarDay__selected:active,
.Home .SingleDatePicker .CalendarDay.CalendarDay__selected:hover {
	background: #525252;
	border: 1px double #525252;
}

.Home .SingleDatePicker .DayPickerKeyboardShortcuts_show__bottomRight::before {
	border-right: 33px solid #525252;
}

.Home .Home-content {
	height: 100vw;
	background-image: url('/corner-bldg.svg');
	background-repeat: no-repeat;
	background-position: bottom;
	width: 100vw;
	padding: 75px 10% 0;
	background-size: 80vw;
	background-color: #fff;
	margin: auto;
}

@media only screen and (max-width: 1120px) {
	.Home .Home-bg {
		background-size: contain;
	}
}

@media only screen and (max-width: 777px) {
	.Home .Home-ctaContainer {
		width: 95%;
		max-width: 400px;
		padding: 20px;
		margin: auto;
	}

	.Home.Home-bg {
		background-size: contain;
		background-position: bottom;
	}

	.Home .Home-dropdown,
	.Home #home_date-selector {
		margin-bottom: 12px;
	}

	.Home .underConstruction {
		margin-top: 65px;
	}

	.Home .Home-dropdown .btn-success.dropdown-toggle,
	.Home .btn-success:not(:disabled):not(.disabled):active:focus {
		width: 100%;
	}

	.Home .Home-dropdown .dropdown-menu.show {
		width: 100%;
	}

	.Home .Home-ctaContainer .SingleDatePickerInput,
	.Home .Home-ctaContainer .SingleDatePickerInput .DateInput,
	.Home .Home-ctaContainer .SingleDatePickerInput .DateInput .DateInput_input {
		width: 100%;
		text-align: center;
	}
}
