.Page .Page-logo {
	display: flex;
	align-items: center;
}

.Page .Page-logo img {
	height: 33px;
	width: auto;
}

.Page .Page-logoText {
	font-size: 16px;
	margin-left: 5px;
}

.Page .Page-logoText span {
	font-weight: 500;
}

.Page .Page-nav {
	background-color: #ffffff;
	display: flex;
	padding: 20px 30px;
	justify-content: space-between;
	align-items: center;
	align-content: center;
	border-bottom: 1px solid #e7e7e7;
	position: relative;
	width: 100%;
	z-index: 999;
}

.Page .Page-nav ul {
	display: flex;
	margin-bottom: 0;
}

.Page .Page-nav ul li {
	font-size: 19px;
	list-style: none;
	margin: 0 20px;
}

.Page .Page-nav button {
	background: none;
	border: none;
	font-size: 19px;
}

.Page .Page-nav a,
.Page .Page-nav button {
	text-decoration: none;
	color: #333;
}

.Page .Page-nav button {
	text-decoration: none;
	color: #333;
	font-size: 19px;
}

.Page .Page-nav ul li a:hover {
	cursor: pointer;
}
