.ViewData .ViewData-comps .CompCard {
	margin-bottom: 30px;
	margin-left: 10px;
	height: 450px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.ViewData .ViewData-comps .CompCard h2 {
	font-size: 14px;
}

.ViewData .ViewData-comps .CompCard p {
	margin-top: -5px;
}

.ViewData .ViewData-comps .CompCard h4 {
	font-size: 14px;
	margin-top: 12px;
}

.ViewData .ViewData-comps .CompCard img {
	width: 100%;
	height: 300px;
	object-fit: cover;
}

.ViewData .ViewData-comps .CompCard .CompCard-actions {
	display: flex;
	justify-content: space-between;
	padding-left: 20px;
}

.ViewData .compCardBody {
	height: 300px;
	width: 300px;
}

.ViewData .compCardMedia {
	height: 300px;
	width: 300px;
	background-color: #e5e5e5;
}

.ViewData .ViewData-comps .CompCard .CompCard-actions button:focus,
.ViewData .ViewData-comps .CompCard .CompCard-actions button:active,
.ViewData .ViewData-comps .CompCard .CompCard-actions button:visited {
	outline: none !important;
}

.ViewData .ViewData-comps .CompCard .CompCard-attachmentsBtn {
	border-color: #7d849a;
	color: #7d849a;
	font-size: 10px;
}
