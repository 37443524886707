body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

div.MuiDialog-container div.MuiDialog-paperWidthLg.MuiDialog-paper {
	max-width: 1427px;
	margin: 0;
}

div.MuiDialog-root {
	top: 74px !important;
}

div.MuiBox-root {
	background-color: #fff;
}

div.MuiCard-root div.MuiBox-root {
	background-color: #f9f9f9;
}

div.MuiCardContent-root.BasicCard-Content div.MuiBox-root {
	background-color: #fff;
}

div.MuiDialog-root div.MuiDialog-container header.MuiAppBar-positionFixed {
	top: 74px !important;
	background-color: #fff;
}

div.MuiDialog-root div.MuiDialog-container div.MuiToolbar-root {
	color: #5a5a5a;
	background-color: #f9f9f9;
}

div.MuiDialog-root
	div.MuiDialog-container
	div.MuiToolbar-root
	h6.MuiTypography-h6 {
	font-weight: 100;
}

div.MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper {
	min-width: 30%;
}

div#attachmentDeleteBtn {
	/*z-index: 999999999 !important;*/
}

button.excelBtn {
	background-color: #359753 !important;
	color: #fff !important;
	font-weight: 600;
}

button.reportBtn {
	background-color: #08649b !important;
	color: #fff !important;
	font-weight: 600;
}

.FilterDrawer-FilterInput
	.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon
	.MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
	padding: 0 3px;
	height: 40px;
}

.FilterDrawer-FilterInput
	.MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']
	.MuiAutocomplete-input:first-child {
	padding-top: 7px;
}

.MuiDrawer-paper {
	width: 525px;
}

div.MuiPaper-root .MuiPopover-paper .MuiPaper-elevation8 .MuiPaper-rounded {
	/*z-index: -1 !important;*/
}
