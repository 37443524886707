div.gm-style div.clusterClass div {
	width: 40px !important;
}

div.gm-style div.clusterClass img {
	top: -20px !important;
	left: -26px !important;
	width: 90px !important;
}

div.gm-style div.clusterClass div {
	display: flex !important;
	color: #fff !important;
	font-size: 10px !important;
	align-items: center !important;
	align-content: center !important;
	justify-content: center !important;
	height: 100% !important;
	line-height: 14px !important;
}

div.markerLabelContainerClass {
	width: 30px;
	height: 30px;
	font-size: 12px;
	border-radius: 50% 50% 50% 0;
	background: #e84839;
	position: absolute;
	transform: rotate(-45deg);
	left: 50%;
	top: 50%;
	margin: -20px 0 0 -20px;
	animation-name: 'bounce';
	animation-fill-mode: both;
	animation-duration: 1s;
}

div.markerLabelContainerClass div.markerLabelClass {
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 7px 0px;
	transform: rotate(45deg);
	font-size: 14px;
	color: #fff;
}

div.gm-style div.infoText {
	width: 300px !important;
}

div.gm-style div.infoText img {
	width: 100% !important;
	overflow: hidden;
}

.imageContainer {
	display: flex;
	height: 300px;
	align-items: center;
	overflow: hidden;
}

.imageContainer:hover {
	cursor: pointer;
}

.imageContainer .noImage {
	width: 300px !important;
	height: 300px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #b1b1b1;
	color: #fff;
	font-size: 14px;
	font-weight: 500;
}
