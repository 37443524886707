.DynamicInput {
	padding: 10px;
	height: auto;
	width: 200px;
}

.DynamicInput .DynamicInput-Wrapper {
	align-items: center;
}

.DynamicInput .DynamicInput-input input::placeholder {
	color: #333;
	opacity: 1;
}

.DynamicInput .DynamicInput-input input:-ms-input-placeholder {
	/* Internet Explorer 10-11 */
	color: #333;
}

.DynamicInput .DynamicInput-input input::-ms-input-placeholder {
	/* Microsoft Edge */
	color: #333;
}

.DynamicInput .DynamicInput-Wrapper label span {
	font-size: 14px;
}

.DynamicInput-datePicker {
	display: flex;
	justify-content: center;
	align-content: center;
	align-items: center;
	flex-direction: column-reverse;
}

.DynamicInput-datePicker h4 {
	font-size: 14px;
	font-weight: 300;
	font-style: italic;
}

.DynamicInput .DynamicInput-Wrapper .dateInputWrapper svg {
	margin-left: -24px;
}

.DynamicInput .DynamicInput-Wrapper svg:hover {
	cursor: pointer;
}

.DynamicInput .dateInputWrapper {
	width: 173px;
	border: 1px solid #b7b7b7;
	border-radius: 3px;
	width: 180px;
	position: relative;
}

.DynamicInput .dateInputWrapper svg.MuiSvgIcon-root {
	position: absolute;
	right: -12px;
	top: 4px;
}

.DynamicInput .dateInputWrapper input {
	width: 130px;
	font-size: 16px;
	border: none;
	border-radius: 3px;
	padding-top: 5px;
	padding-bottom: 5px;
	padding-left: 10px;
}

.FormBox
	.FormBox-inputsContainerVertical
	.FormBox-verticalCol
	.dateInputWrapper,
.FormBox
	.FormBox-inputsContainerVertical
	.FormBox-verticalCol
	.dateInputWrapper
	.DateInput {
	width: 108px;
}

.DynamicInput .textInputWrapper,
.DynamicInput .numberInputWrapper,
.DynamicInput .moneyInputWrapper,
.DynamicInput .percentInputWrapper,
.DynamicInput .sfInputWrapper {
	display: flex;
	border: 1px solid #b7b7b7;
	border-radius: 3px;
	height: 100%;
}

.DynamicInput .textInputWrapper input,
.DynamicInput .numberInputWrapper input,
.DynamicInput .moneyInputWrapper input,
.DynamicInput .percentInputWrapper input,
.DynamicInput .sfInputWrapper input {
	font-size: 16px;
	border: none;
	height: 100%;
	border-radius: 3px;
	padding-top: 5px;
	padding-bottom: 5px;
	padding-left: 5px;
}

.DynamicInput .DateInput {
	border-radius: 3px;
}

.FormBox
	.FormBox-inputsContainerVertical
	.FormBox-verticalCol
	.DynamicInput
	input {
	width: 108px;
}

.FormBox
	.FormBox-inputsContainerVertical
	.FormBox-verticalCol
	.DynamicInput-Wrapper {
	width: 110px;
}

.FormBox .FormBox-inputsContainerVertical .FormBox-verticalCol {
	width: 132px;
}

.AddData-formSectionG div,
.AddData-formSectionG textarea {
	width: 100%;
}

.AddData-formSectionG textarea {
	border-radius: 5px;
	border: 1px solid #c1bbbb;
	padding-left: 10px;
	padding-right: 10px;
}
