.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

body.ReactModal__Body--open .ReactModalPortal .ReactModal__Content {
	top: 100px;
	left: calc(50% - 230px);
	position: absolute;
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

.AuthModalOverlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #353535;
	height: 100vh;
	width: 100vw;
	z-index: 10;
	opacity: 0.8;
}

.ParentAuthOverlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 15;
}

.AuthModal {
	position: absolute;
	top: 50%;
	left: 50%;
	right: auto;
	bottom: auto;
	overflow: auto;
	outline: none;
	margin-right: -50%;
	transform: translate(-50%, -50%);
	z-index: 20 !important;
	background: transparent;
	border: none;
}

.App .nav {
	text-align: center;
}
