.FilterDrawer {
	display: flex;
	margin-left: 10px;
}

.FilterDrawer-toggle {
	padding: 0 20px !important;
	border: 1px solid #4c84b3 !important;
}

button.FilterDrawer-toggle:focus,
button.FilterDrawer-toggle:visited,
button.FilterDrawer-toggle:active,
.FilterDrawer-btns button:focus,
.FilterDrawer-btns button:visited,
.FilterDrawer-btns button:active {
	outline: 0;
}

.FilterDrawer-top {
	display: flex;
	justify-content: space-between;
	align-content: center;
	align-items: center;
	height: 70px;
	margin-top: 12px;
}

li.FilterDrawerItem {
	border-top: 1px solid #e7e7e7;
	display: flex;
	flex-direction: column;
}

li.FilterDrawerItem div.filterTitle {
	font-size: 17px;
	width: 100%;
	margin-left: 20px;
}

.FilterDrawer-title h4 {
	padding-bottom: 0;
	padding-left: 28px;
	margin-bottom: 0;
}

.FilterDrawer-priceRange {
	display: flex;
	flex-direction: column;
}

.FilterDrawer-inputs {
	display: flex;
	width: 100%;
}

.FilterDrawer-closeBtn {
	display: flex;
	justify-content: flex-end;
	margin-right: 5px;
}

.FilterDrawer-btns {
	display: flex;
	justify-content: space-around;
	margin-bottom: 30px;
	position: absolute;
	bottom: 0;
	width: 100%;
}

li.FilterDrawerItem.FilterDrawer-AutoInput {
	align-items: flex-start;
	padding-left: 25px;
	padding-bottom: 16px;
}

li.FilterDrawerItem.FilterDrawer-AutoInput div.filterTitle {
	margin: 0;
	margin-bottom: 10px;
}

.FilterDrawerItem
	.FilterDrawer-FilterInput
	.FilterInput
	.MuiInputBase-root.MuiAutocomplete-inputRoot {
	height: 100% !important;
	padding-right: 65px !important;
}
